import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import MobileBackButton from '../../components/molecules/MobileBackButton';
import { postViewPageAction, postVinLookupAction } from '../../services/nitroService';
import { postVinLookup } from '../../services/vinLookupService';
import '../../styles/css/vinLookup.css';
import { PdfModal } from './pdfModal';
import { vinAnalytics } from './vinAnalytics';
import VinContent from './vinContent';
import VinHeader from './vinHeader';
import VinInput from './vinInput';
import { postMessageToApp } from '../utils/hybridAppHelpers';

export const PdfStatus = {
  DEFAULT: 0,
  GENERATING: 1,
  READY: 2,
  ERROR: 3,
};

export const VinLookup = () => {
  const [vin, setVin] = useState('');
  const [vinUrl, setVinUrl] = useState('');
  const [vehicleYearAndName, setVehicleYearAndName] = useState('');
  const [vehicleName, setVehicleName] = useState('');
  const [vehicleYear, setVehicleYear] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);
  const [modalOpenedType, setModalOpenedType] = useState('download');
  const [pdfStatus, setPdfStatus] = useState(PdfStatus.DEFAULT);

  useEffect(() => {
    postViewPageAction(window.location.href);
    const VIN = window.location.search;
    if(VIN){
      handleOnChange(VIN.split('?')[1]);
    }
    vinAnalytics.pageLoadAnalytics();
  }, []);

  const updateError = (error) => {
    if (error) {
      vinAnalytics.vinSearchErrorAnalytics(vin, error);
    }
    setErrorMessage(error);
    setVehicleYearAndName('');
    setVehicleName('');
    setVehicleYear('');
    setVinUrl('');
    setModalOpenedType('')
    setEmailModalOpen(false);
    setLoading(false);
  };

  async function handleSuccess(curVin, response) {
    vinAnalytics.vinSearchResultLoadAnalytics(curVin, response.yearAndName);
    postVinLookupAction();
    if (response.url) {
      setVehicleYearAndName(response.yearAndName);
      setVehicleName(response.name);
      setVehicleYear(response.year);
      setVinUrl(response.url);
      setErrorMessage('');
      setModalOpenedType('')
      setLoading(false);
    } else {
      updateError(`Temporarily unable to retrieve the pdf for ${response.yearAndName}. Please try again`);
    }
  }

  const handleFailure = (response) => {
    const message = `${response.Error}. ${response.Message}`;
    updateError(message);
  };

  async function getVinPDF(curVin) {
    setLoading(true);
    vinAnalytics.vinSearchLoadingAnalytics(curVin);
    vinAnalytics.vinSearchedAnalytics(curVin);
    const response = await postVinLookup(curVin);
    if (response.Error || !response.yearAndName) {
      handleFailure(response);
    } else {
      handleSuccess(curVin, response);
    }
  }

  const handleOnChange = (vin) => {
    setPdfStatus(PdfStatus.DEFAULT);
    setVinUrl(null);
    setErrorMessage(null);

    if (vin.length != 17) {
      updateError(undefined);
      handleOnBlur(vin);
      setVin(vin);
    }else {
      setVin(vin);
      getVinPDF(vin);
    }
  };

  const handleOnBlur = (vin) => {
    if (vin.length > 0 && vin.length < 17) {
      updateError(`The VIN you entered only has ${vin.length} characters. <br/> **Please try again.**`);
    }
    if (vin.length > 17) {
      updateError('The VIN you entered has too many characters. <br/> **Please try again.**');
    }
  };

  const handleDownloadClick = async (callback = () => {}, type = 'download') => {
    setModalOpenedType(type);

    // Don't have to re-check the same PDF if user clicks download twice
    if (pdfStatus === PdfStatus.READY) {
      callback();
    } else {
      setPdfStatus(PdfStatus.GENERATING);
      const pdfIsAvailable = await checkPdfStatus();

      if (pdfIsAvailable) {
        setPdfStatus(PdfStatus.READY);
      } else {
        setPdfStatus(PdfStatus.ERROR);
      }
    }
    vinAnalytics.vinDownloadClickAnalytics(vin, vehicleYearAndName);
  };

  const openEmailModal = () => {
    vinAnalytics.vinShareClickAnalytics(vin, vehicleYearAndName);
    setEmailModalOpen(true);
  };

  const closeEmailModal = () => {
    setEmailModalOpen(false);
  };

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async function attemptWithDelay(maxAttempts, retryDelay, func) {
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
        if (attempt > 0) {
            // Last attempt failed, wait a moment
            await delay(retryDelay);
        }
        try {
            const response = await func();
            if (response) {
              // Success
              return;
            } else {
            }
        } catch {
        }
    }
    throw new Error("Out of retries");
  }

  // Determines if "date" occurred less than "maxHours" hours ago
  function isWithinHours(date, maxHours) {
    const now = new Date();
    const msBetweenDates = Math.abs((new Date(date)).getTime() - now.getTime());

    // Convert milliseconds to hours
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

    return hoursBetweenDates < maxHours;
  }

  async function checkForRecentS3Object() {
    const response = await postVinLookup(vin);
    return response && response.url;
  }

  async function checkPdfStatus() {
    setPdfModalIsOpen(true);
  
    try {
      // Attempt to find newly generated PDF for 30 seconds
      await attemptWithDelay(15, 2000, () => checkForRecentS3Object())
      return true;
    }
    catch {
      // PDF has not been generated
      return false;
    }
  }

  const openPdfModal = () => {
    setPdfModalIsOpen(true);
  };

  const closePdfModal = () => {
    setPdfModalIsOpen(false);
  };

  function resetResults () {
    setVinUrl(null);
  }

  const scanVin = () => {
    postMessageToApp('Scan VIN');
    
    // Remove this after app refactor
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('Scan VIN');
    }
  }

  useEffect(() => {
    if (window.vin) {
      setVin(window.vin);
      getVinPDF(window.vin);
    }
  }, [window.vin]);
 
  return (
    <div className="vinLookupDiv">
      {
        window.ReactNativeWebView &&
        <MobileBackButton />
      }
      <VinHeader />
      {
        window.ReactNativeWebView &&
        (<div className='scanVinButtonContainer'>
          <button className='scanVinButton' onClick={scanVin}>Scan VIN</button>
        </div>)
      }
      <VinInput
        resetResults={resetResults}
        vin={vin}
        errorMessage={errorMessage}
        handleOnChange={handleOnChange}
        handleOnBlur={handleOnBlur}
      />
      {!errorMessage && (loading || vinUrl)
        && <VinContent
          vinUrl={vinUrl}
          vehicleName={vehicleName}
          vehicleYear={vehicleYear}
          vin={vin}
          loading={loading}
          emailModalOpen={emailModalOpen}
          openEmailModal={openEmailModal}
          closeEmailModal={closeEmailModal}
          handleDownloadClick={handleDownloadClick}
        />
        }
        {
          pdfModalIsOpen
          && (
            <PdfModal
              status={pdfStatus}
              openEmailModal={openEmailModal}
              openModal={openPdfModal}
              closeModal={closePdfModal}
              modalType={modalOpenedType}
              url={vinUrl}
            />
          )
        }
    </div>
  );
};

export default injectIntl(VinLookup);
