import 'whatwg-fetch';
import httpOption from './httpOption';
import { VSPEC_PDF_ENDPOINT } from '../constants/endpoints';

export async function postVinLookup(vin) {
  const url = `${VSPEC_PDF_ENDPOINT}/${vin}`;
  const options = httpOption.GET();

  const response = await fetch(url, options)
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }
  return Promise.reject(response);
}