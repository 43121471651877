import React, { useState, useEffect, useContext, useLayoutEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import SubheaderV2 from "../subheaderV2/subheaderV2";
import ModelOverviewV2 from "../modelOverviewV2/modelOverviewV2";

import AccessoriesComponent from "../accessories/accessories.component";
import CompetitiveAdvantagesComponent from "../competitiveAdvantages/competitiveAdvantages.component";
import FeaturesAndSpecs from "../featuresAndSpecs/featuresAndSpecs";
import ModelOverviewComponent from "../modelOverview/modelOverview.component";
import TCOPComponent from "../TCOP/TCOP.component";
import TrimWalkComponent from "../trimWalk/trimWalk.component";
import VehicleOverviewComponent from "../vehicleOverview/vehicleOverview.component";
import VehicleResourceComponent from "../vehicleResource/vehicleResource.component";
import LocalIncentives from "../localIncentives/localIncentives";
import Display404NotFound from "../../components/atoms/404NotFound";
import VehicleResourcesV2 from "../vehicleResourceV2/vehicleResourcesV2";
import VehicleDetails from "../vehicleDetails/vehicleDetails";
import DropdownV2 from "../../components/molecules/DropdownV2";

import { getVehicleYears } from "../../services/vehicleService";
import { getDynamoName, getUrlModelName, getFormattedName } from "../utils/vehicleName";
import { WindowSize } from "../../components/contexts/windowSizeContext";
import getMisc from "../../services/miscService";
import getNavigation from "../../services/navigationService";
import getSpotlightGallery from "../../services/spotlightService";
import { getYearsFromRange, loadingStatus, yearInRange, getBookmarkSubcategory, buildVpUrl, updateSidebarNavData, processSpecsNavItems } from "./VPUtils";
import MobileBackButton from "../../components/molecules/MobileBackButton";
import applyMarkdown from "../utils/applyMarkdown";
import * as analyticsFunctions from "../subheaderV2/subheaderV2.analytics";
import AppSettings from "../../appsettings.js";
import { isCurrentVersionLower } from "../utils/appMinimumVersionVerify";
import { isMobileView } from "../utils/getScreenSize";
import { getFeaturesAndSpecs } from "../../services/featuresAndSpecsService";
import { printableDocumentEvents } from "../../utils/printableUtils";
import { VehiclePageIsPrint } from "../../components/contexts/vehiclePagePrintContext";

const VPRouter = (props) => {
  const [modelYearsList, setModelYearsList] = useState(null);
  const [showVpV2, setShowVpV2] = useState(null);
  const [subheaderData, setSubheaderData] = useState(null);
  const [subheaderTabs, setSubheaderTabs] = useState(null);
  const [mobileSubheaderTabs, setMobileSubheaderTabs] = useState(null);
  const [currentTab, setCurrentTab] = useState(null);
  const [VPNavData, setVPNavData] = useState(null);
  const [menuItems, setMenuItems] = useState(null);
  const [externalLinks, setExternalLinks] = useState(null);
  const [towingPayloadModels, setTowingPayloadModels] = useState([]);
  const [comparisonYears, setComparisonYears] = useState([]);
  const [isLoadingFeaturesAndSpecs, setIsLoadingFeaturesAndSpecs] = useState(loadingStatus.NOT_STARTED);
  const [featuresAndSpecsData, setFeaturesAndSpecsData] = useState(null);
  const [innerTitleStyling, setInnerTitleStyling] = useState({});
  const [outerTitleStyling, setOuterTitleStyling] = useState({});

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { isPrint, setIsPrint } = useContext(VehiclePageIsPrint);
  const query = new URLSearchParams(location.search);

  const { device } = useContext(WindowSize);

  const showTowingPayloadMenu = useMemo(() => {
    return towingPayloadModels.some((modelItem) => {
      // filter by T&P MY range
      return getUrlModelName(modelItem.modelName) === params.model.toUpperCase();
    });
  }, [params.model, params.year, towingPayloadModels])

  useEffect(() => {
    setSubheaderData(null)
    setSubheaderTabs(null)
    setMobileSubheaderTabs(null)
    setMenuItems(null)
    setExternalLinks(null)
  }, [params.year])

  useEffect(async () => {
    setIsLoadingFeaturesAndSpecs(loadingStatus.LOADING);
    await getFeaturesAndSpecs(getDynamoName(params.model), parseInt(params.year, 10), "en")
      .then((data) => {
        setFeaturesAndSpecsData(data?.featuresAndSpecs);
        setIsLoadingFeaturesAndSpecs(loadingStatus.DONE);
      })
      .catch((error) => {
        console.log(`ERROR: failed to get features and specs data. ${error}`);
        setIsLoadingFeaturesAndSpecs(loadingStatus.FAILED);
      })
  }, [params.model, params.year]);

  useEffect(() => {
    getNavigation("vehicle-pages")
      .then((data) => {
        setVPNavData(data.items);
      })
      .catch((error) => {
        console.log(
          `ERROR: failed to load VP navigation data. ${JSON.stringify(
            error.statusText
          )}`
        );
      });

    // call to getMisc
    getMisc("showVPOverviewV2")
      .then((data) => {
        setShowVpV2(data.vehicles.includes(params.model.toLowerCase()));
      })
      .catch((error) => {
        console.log(`ERROR: failed to show Overview V2 flag data. ${error}`);
      });
  }, [params.model]);

  useEffect(async () => {
    const links = externalLinks
      ? { ...externalLinks }
      : {
          eb: null,
          dc: null,
          sb: null,
          tp: null,
        };
    if(params.model && params.year) {
      links["tp"] = showTowingPayloadMenu ? `/towingPayload/${params.model}/${parseInt(params.year, 10)}` : null;
      await getSpotlightGallery(getDynamoName(params.model), parseInt(params.year, 10), false, "en")
      .then((data) => {
        links["eb"] = data?.eBrochure
          ? `${AppSettings.AWSFileRepo.brochures}${data?.eBrochure?.replace(
              /\*/g,
              ""
            )}`
          : null;
        links["dc"] = data?.deliveryChecklistPath || null
        links["sb"] = data?.sourceBook ||
          `/static/sourcebooks/${parseInt(params.year, 10)}_${getDynamoName(params.model)
            .toLowerCase()
            .replace(" ", "_")}_en_SourceBook.pdf`
      })
      .catch((error) => {
        console.log(`ERROR: failed to get spotlight gallery data. ${error}`);
      });
      setExternalLinks(links);
    }
  }, [params.model, params.year, showTowingPayloadMenu]);

  useEffect(() => {
    if (externalLinks && featuresAndSpecsData) {
      if (params.year >= 2023) {
        const resourcesMenuItems = updateSidebarNavData(VPNavData?.resources, device, externalLinks)
        const detailsMenuItems = updateSidebarNavData(VPNavData?.details, device)
        const specsProcessedDetailsMenuItems = processSpecsNavItems(detailsMenuItems, featuresAndSpecsData)
        setMenuItems({"resources": resourcesMenuItems, "details": specsProcessedDetailsMenuItems});
      } else {
        setMenuItems([])
      }
    }
  }, [VPNavData, externalLinks, device, subheaderData, featuresAndSpecsData])

  useEffect(() => {
    // build list of model years for current vehicle based on launched years and ranges data

    if(VPNavData) {
        getVehicleYears(getDynamoName(params.model))
          .then((launchedYears) => {
            setComparisonYears(launchedYears);
            const modelYearRange = VPNavData.modelYearRanges[params.model.toUpperCase()]
            if(modelYearRange) {
              const combinedSortedYears = [...getYearsFromRange(modelYearRange), ...launchedYears]
              combinedSortedYears.sort((a, b) => a - b);
              combinedSortedYears.reverse()
              return combinedSortedYears;
            } else {
              return launchedYears;
            }
          })
          .then(years => {
            setModelYearsList(years);
          })
          .catch((error) => {
            console.log(`error: ${error}`);
            setModelYearsList([]);
          });
      }
  }, [VPNavData])

  useLayoutEffect(() => {
    // redirect to valid model year if URL year param is missing or invalid
    // or, if year is valid but missing page param, redirect to default page

    if (modelYearsList?.length) {
      if (!isNaN(params.year) && Math.max(...modelYearsList) < params.year) {
        // redirect to the newest one
        history.replace(
          `/product/${getUrlModelName(params.model)}/${parseInt(
            Math.max(...modelYearsList),
            10
          )}`
        );
      } else if (
        !isNaN(params.year) &&
        Math.min(...modelYearsList) > params.year
      ) {
        // redirect to the oldest one
        history.replace(
          `/product/${getUrlModelName(params.model)}/${Math.min(
            ...modelYearsList
          )}`
        );
      } else if (
        isNaN(params.year) ||
        !params.page ||
        !modelYearsList.includes(parseFloat(params.year))
      ) {
        const currentYear =
          !isNaN(params.year) &&
          modelYearsList.includes(parseFloat(params.year))
            ? params.year
            : parseInt(modelYearsList[0], 10);
        const pageRoute =
          VPNavData?.subheader.defaultPages?.find(
            (p) =>
              yearInRange(currentYear, p.startYear, p.endYear) ||
              currentYear == p.year
          )?.page || "";
        history.replace(
          `/product/${getUrlModelName(
            params.model
          )}/${currentYear}/${pageRoute}`
        );
      }
    }
  }, [modelYearsList, params.year, params.page]);

  /*
  Subheader tabs are driven by multiple subheader data objects sourced from dynamo:
  - yearTabs: List of tabs to display for ranges of years or individual years
  - tabs: tab details mapped by yearTabs lists
  - defaultPage: default page for ranges of years or individual years
  */

  useEffect(() => {
    // get subheader tabs and clear page param if invalid for that year
    if (params.year && VPNavData) {
      // find tabs where the current year is within range
      const VPTabs = VPNavData.subheader.yearTabs
        .find(
          (y) =>
            yearInRange(parseFloat(params.year), y.startYear, y.endYear) ||
            parseFloat(params.year) == y.year
        )
        ?.tabs?.map((t) => VPNavData.subheader.tabs[t]);
      //if page param not an option in VPTabs, redirect to default page
      if (
        params.page &&
        !VPTabs?.find(
          (t) => t.urlPath.toLowerCase() === params.page?.toLowerCase()
        )
      ) {
        history.replace(
          `/product/${getUrlModelName(params.model)}/${params.year}`
        );
        return;
      } else {
        setSubheaderData(VPTabs);
      }
      setSubheaderTabs(VPTabs);
    }
  }, [params.year, params.page, VPNavData]);

  useEffect(() => {
    if(subheaderData) {
      const currTab = subheaderData?.find(
        (t) => t.urlPath === window.location.pathname.split("/").slice(4)[0]
      )
      setCurrentTab(currTab);
    }
  }, [params.year, params.page, params.subPage, subheaderData]);

  useEffect(() => {
    if(subheaderData && menuItems) {
      setMobileSubheaderTabs(subheaderData.map(s => {
        if (menuItems[s.id.toLowerCase()]) {
          return {"nestedItems": menuItems[s.id.toLowerCase()], ...s}
        } else return {...s};
      }))
    }
  }, [subheaderData, menuItems]);

  // Just on component first-time render
  useEffect(() => {
    getMisc('towingPayload', 'en').then((tpData) => {
      setTowingPayloadModels(tpData.items.vehicles)
    }).catch((error) => console.log(`error: ${JSON.stringify(error)}`))

    const beforePrintHandler = () => {
      setIsPrint(true);
      setInnerTitleStyling({fontSize: '11px', fontWeight: 'bold', fontFamily: 'ToyotaType-Regular'});
      setOuterTitleStyling({fontSize: '14px', fontWeight: 'bold', fontFamily: 'ToyotaType-Bold'});
    };

    const afterPrintHandler = () => {
      setIsPrint(false);
      setInnerTitleStyling({});
      setOuterTitleStyling({});
    };

    const printHandler = new printableDocumentEvents(beforePrintHandler, afterPrintHandler);

    printHandler.subscribe();

    return () => {
      printHandler.unsubscribe();
    };
  }, [])

  const route = {
    engine: query.get('engine'),
    featSpec: query.get('featSpec'),
    model: getDynamoName(params.model),
    path: params.page,
    trim: query.get('trim'),
    year: params.year,
    type: query.get('type')
  };

const modelName = useMemo(() => {
  // Expression to calculate the number of characters related to the screen size
  const maxChar = 14 - Math.ceil((425 - window.innerWidth) / 11);
  const isMobile = isMobileView(window.innerWidth);
  const formattedModelName = getFormattedName(getDynamoName(params.model), true, parseInt(params.year));
  if (!isMobile) return formattedModelName;

  const bigName =
    formattedModelName.replace(/\*\*/g, "").length > maxChar + 1;
  if (!bigName) return formattedModelName;

  const startsWithAsteristic = formattedModelName.startsWith("**");
  const formattedName =
    isMobile && bigName
      ? formattedModelName.substring(startsWithAsteristic ? 2 : 0, maxChar) +
        "..."
      : formattedModelName;
  return formattedName;
}, [window.innerWidth, params.model, params.year]);

const getModelYearDropdown = () =>
  modelYearsList ? (
    <DropdownV2
      options={modelYearsList}
      value={
        <div>
          {params.year + " "}
          <b>{applyMarkdown(modelName)}</b>
        </div>
      }
      onValueChange={(e) => {
        history.push(buildVpUrl(getUrlModelName(getDynamoName(params.model)), e));

        // ANALYTICS
        analyticsFunctions.modelYearDropdownClick({
          modelName: getDynamoName(params.model),
          modelYear: params.year,
        });
      }}
      style={"fullBackground"}
    />
  ) : (
    <Skeleton width={200} height={20} />
  );

  const getVPPageComponent = () => {
    if (!isNaN(params.year) && params.page && subheaderData && modelYearsList) {
      switch (params.page?.toLowerCase()) {
        case "modeloverview":
          if (params.year >= 2023 && showVpV2) {
            return (
              <ModelOverviewV2
                model={params.model}
                year={parseInt(params.year, 10)}
                device={device}
              />
            );
          } else if (params.year < 2023 || showVpV2 === false) {
            return (
              <ModelOverviewComponent
                {...props}
                params={params}
                route={route}
              />
            );
          } else break;
        case "featuresandspecs":
          if (params.year >= 2023) {
            history.replace(
              `/product/${getUrlModelName(params.model)}/${params.year}/details`
            );
            return;
          }
          return <FeaturesAndSpecs {...props} params={params} route={route} />;
        case "tcop":
          if (params.year >= 2023) {
            history.replace(
              `/product/${getUrlModelName(params.model)}/${params.year}/details`
            );
            return;
          }
          return <TCOPComponent {...props} params={params} route={route} />;
        case "trimwalk":
          if (params.year >= 2023) {
            history.replace(
              `/product/${getUrlModelName(params.model)}/${
                params.year
              }/resources/comparison/trim-walk`
            );
            return;
          }
          return <TrimWalkComponent {...props} params={params} route={route} />;
        case "accessories":
          if (params.year >= 2023) {
            history.replace(
              `/product/${getUrlModelName(params.model)}/${
                params.year
              }/details/interior-accessories`
            );
            return;
          }
          return (
            <AccessoriesComponent {...props} params={params} route={route} />
          );
        case "overview":
          return (
            <VehicleOverviewComponent
              {...props}
              params={params}
              route={route}
            />
          );
        case "resources":
          if (params.year >= 2023) {
            return (
              <VehicleResourcesV2
                model={getDynamoName(params.model)}
                year={parseInt(params.year, 10)}
                device={device}
                menuItems={menuItems?.resources}
                innerTitleStyling={innerTitleStyling}
                outerTitleStyling={outerTitleStyling}
              />
            );
          }
          return (
            <VehicleResourceComponent
              {...props}
              params={params}
              route={route}
            />
          );
        case "competitiveadvantages":
          if (params.year >= 2023) {
            history.replace(
              `/product/${getUrlModelName(params.model)}/${
                params.year
              }/resources/comparison/${
                params.subPage == "thirdPartyComparison"
                  ? "third-party-comparison"
                  : "head-to-head"
              }`
            );
            return;
          } else if (!params.subPage) {
            const defaultPage = params.year > 2019 ? "headToHead" : "overview";
            history.replace(
              `/product/${getUrlModelName(params.model)}/${
                params.year
              }/competitiveAdvantages/${defaultPage}`
            );
            return;
          } else if (params.subPage === "edge") {
            params.subPage = "headToHead";
          } else if (params.subPage === "overview" && params.year > 2019) {
            params.subPage = "headToHead";
          }
          return (
            <CompetitiveAdvantagesComponent
              {...props}
              params={params}
              route={route}
              subPage={params.subPage}
            />
          );
        case "incentives":
          if (params.year >= 2023) {
            history.replace(
              `/product/${getUrlModelName(params.model)}/${
                params.year
              }/resources/incentives`
            );
            return;
          }
          return <LocalIncentives {...props} params={params} route={route} />;
        case "details":
          return (
            <VehicleDetails
              comparisonYears={comparisonYears}
              isLoadingFeaturesAndSpecs={isLoadingFeaturesAndSpecs}
              featuresAndSpecsData={featuresAndSpecsData}
              model={getDynamoName(params.model)}
              year={parseInt(params.year, 10)}
              device={device}
              menuItems={menuItems?.details}
              params={params}
              route={route}
              {...props}
            />
          );
        default:
          history.replace(
            `/product/${getUrlModelName(params.model)}/${params.year}`
          );
          return;
      }
    }
  };

  if (modelYearsList?.length === 0) {
    return <Display404NotFound display />;
  } else {
    return (
      <div className="VPRouter">
        {isMobileView(window.innerWidth) &&
          modelYearsList &&
          !isCurrentVersionLower("12.0.17") && (
            <MobileBackButton
              containerStyle={{
                padding: "12px",
                position: "absolute",
                top: "20px",
                left: "12px",
                display: "flex",
                placeContent: "center",
                width: "12px",
                height: "12px",
              }}
              style={{ top: "4px", left: "7px", filter: "invert(100%)" }}
            />
          )}
        <SubheaderV2
          comparisonTools={VPNavData?.resources?.find((r)=> r.title == "Comparison Tools").nestedItems}
          title={getModelYearDropdown()}
          baseUrl={`/product/${params.model.toUpperCase()}`}
          device={device}
          tabs={subheaderTabs}
          mobileTabs={mobileSubheaderTabs}
          currentTab={currentTab}
          bookmarkTitle={`${params.year} ${getFormattedName(getDynamoName(params.model))}`}
          bookmarkSubcategory={(sc)=>getBookmarkSubcategory(sc)}
          analyticsPageData={{
            pageTitle: getFormattedName(getDynamoName(params.model)),
            modelName: getFormattedName(getDynamoName(params.model)),
            modelYear: params.year,
            navPage: params.page,
            subnavPage: params.subPage
          }}
          navClick={(page, subpage)=>history.push(buildVpUrl(getUrlModelName(getDynamoName(params.model)), params.year, page, subpage))}
          bookmarkable
          mobileVerticalMenu={true}
        />
        {getVPPageComponent()}
      </div>
    );
  }
};

export default VPRouter;
