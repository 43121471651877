import { jwtDecode } from "jwt-decode";
import { User } from "oidc-client";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { UserManagerContext } from "../../components/contexts/userManagerContext";
import { fetchUserInfo, getUserDataApp, removeAuthInfo } from "../../services/authenticationService";
import { postMessageToApp } from "../utils/hybridAppHelpers";
import { postUserActivityToRDS } from "../../services/userActivityService";

const PrivateRoute = ({ children, ...props }) => {
    const LOGIN_STATE = {
        NOT_LOGGED: -1,
        LOADING: 0,
        LOGGED: 1,
    };
    const rememberUrlKey = "desiredNav";
    const { userManager } = useContext(UserManagerContext);
    const [loggedState, setLoggedState] = useState(LOGIN_STATE.LOADING);
    const location = useLocation();
    const prevLocationRef = useRef('');

    useEffect(() => {
        const path = location.pathname
        if (path !== prevLocationRef.current.pathname) {
          // TODO: reenable -> postUserActivityToRDS(path);
        }
    
        prevLocationRef.current = location;
    }, [location]);

    const redirectIfNeeded = () => {
        const previousUrl = sessionStorage.getItem(rememberUrlKey);
        if (previousUrl) {
            sessionStorage.removeItem(rememberUrlKey);
            // Ensure the previous URL is for Engage only
            if (previousUrl.includes("engage.toyota.com/") || previousUrl.includes("localhost:3000")) {
                window.location = previousUrl;
            }
        }
    };

    const updateUserManagerIfNeeded = async () => {
        const user = await userManager.getUser();
        const userNotSetUp = !user || JSON.stringify(user) === "{}" || user.expired === true;
        const { access_token, id_token, refresh_token, expires_at } = localStorage;
        const authInfoPresent = !!(
            access_token &&
            id_token &&
            refresh_token &&
            expires_at &&
            Date.now() < expires_at
        );
        if (userNotSetUp && authInfoPresent) {
            try {
                const profile = await fetchUserInfo(userManager, access_token);
                const { exp, scope, token_type } = jwtDecode(access_token);
                const { acr, auditTrackingId, auth_time, azp, s_hash, tokenName, tokenType } = jwtDecode(id_token);
                profile.acr = acr;
                profile.auditTrackingId = auditTrackingId;
                profile.auth_time = auth_time;
                profile.azp = azp;
                profile.s_hash = s_hash;
                profile.tokenName = tokenName;
                profile.tokenType = tokenType;

                const newUser = {
                    access_token,
                    expires_at: exp,
                    id_token,
                    profile,
                    refresh_token,
                    scope,
                    token_type,
                };

                const oidcUser = new User(newUser);
                await userManager.storeUser(oidcUser);

                await getUserDataApp(profile);
                window.location.reload();
            } catch (error) {
                console.log("Error updating user manager from new access token.");
                setLoggedState(LOGIN_STATE.NOT_LOGGED);
            }
        }
    };

    const checkIfUserIsLoggedIn = async () => {
        if (userManager) {
            await updateUserManagerIfNeeded();
            const user = await userManager.getUser();
            if (user && user.expired === false) {
                redirectIfNeeded();
                setLoggedState(LOGIN_STATE.LOGGED);
                postMessageToApp("UserDetails", user.profile);
            } else {
                setLoggedState(LOGIN_STATE.NOT_LOGGED);
            }
        }
    };

    useEffect(() => {
        checkIfUserIsLoggedIn();
    }, [userManager, window.location.href]);

    switch (loggedState) {
        case LOGIN_STATE.LOGGED:
            return <Route {...props}>{children}</Route>;
        case LOGIN_STATE.NOT_LOGGED:
            sessionStorage.setItem(rememberUrlKey, window.location);
            return <Redirect to="/logout?source=auto" />;
        case LOGIN_STATE.LOADING:
        default:
            return null;
    }
};

export default PrivateRoute;
